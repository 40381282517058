<template>
  <v-app-bar
    app
    color="white"
    clipped-left
    :height="$vuetify.breakpoint.xsOnly ? 110 : 70"
    :class="classCordova"
  >
    <v-row no-gutters :align="'center'">
      <v-col
        cols="4"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        class="order-0 d-flex justify-sm-center justify-md-center justify-lg-center justify-xl-center justify-xs-left align-center"
      >
        <v-btn
          v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
          :to="{ path: $routerHistory.previous().path }"
          class="back-button"
          aria-label="Torna Indietro"
          icon
        >
          <v-icon medium>mdi-chevron-left</v-icon>
        </v-btn>
        <v-app-bar-nav-icon
          aria-label="Apri menu categorie"
          @click.prevent.stop="drawerLeft = !drawerLeft"
          class="drawer-right"
        >
          <template v-slot:label>
            <v-btn icon aria-label="Apri menu categorie">
              <!-- <v-icon medium>mdi-account-circle-outline</v-icon> -->
              <v-icon medium>$hamburger</v-icon>
            </v-btn>
          </template>
        </v-app-bar-nav-icon>
        <v-menu bottom offset-y left class="" v-if="!$platform_is_cordova">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              aria-label="Link utili"
              v-bind="attrs"
              v-on.stop="on"
              icon
              medium
              color="#583d3e"
              class=".d-none d-sm-flex d-md-none"
            >
              <v-icon medium>$help</v-icon>
            </v-btn>
          </template>
          <v-list class="account-menu pb-0">
            <v-list-item to="/page/regolamento">
              <v-list-item-title>Regolamento</v-list-item-title>
            </v-list-item>
            <v-list-item to="/faq/i-nostri-prodotti">
              <v-list-item-title>Libro ingredienti</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="navTo('https://www.iperal.it/Richiamo-prodotti/')"
            >
              <v-list-item-title>Richiamo prodotti</v-list-item-title>
            </v-list-item>
            <v-list-item to="/consegna-agevolata">
              <v-list-item-title>Agevolazioni di consegna</v-list-item-title>
            </v-list-item>
            <v-list-item to="/faq">
              <v-list-item-title>Domande Frequenti</v-list-item-title>
            </v-list-item>
            <v-list-item to="/servizio-clienti">
              <v-list-item-title>Servizio Clienti</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col
        cols="3"
        sm="2"
        md="1"
        lg="1"
        xl="1"
        class="order-1 justify-center align-center"
      >
        <router-link to="/" class="px-0">
          <v-img
            src="/logo.png"
            alt="Logo Iperal Spesa Online"
            max-height="50px"
            max-width="150px"
            contain
            eager
          />
        </router-link>
      </v-col>
      <v-col
        cols="12"
        sm="5"
        md="4"
        lg="5"
        xl="5"
        class="order-last order-sm-2 d-sm-flex flex-sm-row justify-sm-center align-sm-center pl-sm-2 d-md-flex flex-md-row justify-md-center align-md-center pl-md-2"
        style="flex-wrap: nowrap;"
      >
        <div
          style="min-width: 100px; max-width: 100%;"
          class="flex-grow-1 flex-shrink-0"
        >
          <SearchInput />
        </div>
      </v-col>
      <v-col
        cols="5"
        sm="4"
        md="6"
        lg="5"
        xl="5"
        class="order-3 d-flex justify-end pr-1"
      >
        <div class="justify-lg-center align-lg-end ml-4">
          <CartInfoList
            v-if="isAuthenticated"
            class="d-none d-md-flex d-lg-flex"
          />
        </div>
        <div class="not-auth profile-nav" v-if="!isAuthenticated">
          <v-menu bottom offset-y left class="">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aria-label="Link utili"
                v-bind="attrs"
                v-on.stop="on"
                icon
                medium
                class="info-menu d-none d-md-flex"
              >
                <v-icon medium>$help</v-icon>
              </v-btn>
            </template>
            <v-list class="account-menu pb-0">
              <v-list-item to="/page/regolamento">
                <v-list-item-title>Regolamento</v-list-item-title>
              </v-list-item>
              <v-list-item to="/faq/i-nostri-prodotti">
                <v-list-item-title>Libro ingredienti</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="navTo('https://www.iperal.it/Richiamo-prodotti/')"
              >
                <v-list-item-title>Richiamo prodotti</v-list-item-title>
              </v-list-item>
              <v-list-item to="/consegna-agevolata">
                <v-list-item-title>Agevolazioni di consegna</v-list-item-title>
              </v-list-item>
              <v-list-item to="/faq">
                <v-list-item-title>Domande Frequenti</v-list-item-title>
              </v-list-item>
              <v-list-item to="/servizio-clienti">
                <v-list-item-title>Servizio Clienti</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            aria-label="Accedi o Registrati"
            @click.stop="handleLogin()"
            medium
            icon
          >
            <v-icon medium>$profile</v-icon>
          </v-btn>
          <v-btn
            aria-label="Accedi o Registrati"
            @click.stop="handleLogin()"
            medium
            text
            class="d-none d-sm-inline  pl-0"
          >
            <span v-html="$t('navbar.singup')"></span>
          </v-btn>
          <v-btn
            aria-label="Vai al tuo carrello"
            icon
            @click.stop="handleLogin()"
          >
            <v-icon medium>$cart</v-icon>
          </v-btn>
        </div>
        <div v-else class="cart-navbar profile-nav">
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aria-label="Link utili"
                v-bind="attrs"
                v-on.stop="on"
                icon
                medium
                class="info-menu-logged d-none d-md-flex"
              >
                <v-icon medium>$help</v-icon>
              </v-btn>
            </template>
            <v-list class="account-menu pb-0">
              <v-list-item to="/page/regolamento">
                <v-list-item-title>Regolamento</v-list-item-title>
              </v-list-item>
              <v-list-item to="/faq/i-nostri-prodotti">
                <v-list-item-title>Libro ingredienti</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="navTo('https://www.iperal.it/Richiamo-prodotti/')"
              >
                <v-list-item-title>Richiamo prodotti</v-list-item-title>
              </v-list-item>
              <v-list-item to="/consegna-agevolata">
                <v-list-item-title>Agevolazioni di consegna</v-list-item-title>
              </v-list-item>
              <v-list-item to="/faq">
                <v-list-item-title>Domande Frequenti</v-list-item-title>
              </v-list-item>
              <v-list-item to="/servizio-clienti">
                <v-list-item-title>Servizio Clienti</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            aria-label="Vai alle liste dei preferiti"
            class="d-none d-sm-flex profile-nav"
            to="/profile/lists"
            medium
            icon
          >
            <v-icon medium>$heart</v-icon>
          </v-btn>
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on.stop="on"
                icon
                medium
                aria-label="Vai al tuo profilo"
              >
                <!-- <v-icon medium>mdi-account-circle-outline</v-icon> -->
                <v-icon medium>$profile</v-icon>
              </v-btn>
            </template>
            <v-list class="account-menu pb-0">
              <!-- <v-list-item to="/profile">
              <v-list-item-title>Panoramica</v-list-item-title>
            </v-list-item> -->
              <v-list-item to="/profile/profile-update">
                <v-list-item-title>{{
                  $t("dashboard.profile")
                }}</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item to="/vantaggi">
                <v-list-item-title>{{
                  $t("dashboard.vantaggi")
                }}</v-list-item-title>
              </v-list-item> -->
              <!-- hide vantaggi -->
              <v-list-item :to="{ name: 'ProfileGift' }">
                <v-list-item-title>{{
                  $t("dashboard.vantaggi")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item to="/profile/orders">
                <v-list-item-title>{{
                  $t("dashboard.orders")
                }}</v-list-item-title>
              </v-list-item>

              <v-list-item to="/profile/addresses">
                <v-list-item-title>{{
                  $t("dashboard.addresses")
                }}</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item to="/profile/coupons">
              <v-list-item-title>Promozioni esclusive</v-list-item-title>
            </v-list-item> -->
              <v-list-item to="/profile/lists" class="d-flex d-sm-none">
                <v-list-item-title>{{
                  $t("dashboard.lists")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item to="/profile/news-list">
                <v-list-item-title>{{
                  $t("dashboard.news")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="doLogout()" class="logout-item">
                <!-- <v-list-item-title>Logout</v-list-item-title> -->
                <v-btn width="100%" small outlined color="primary">{{
                  $t("dashboard.logout")
                }}</v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-badge
            :content="totalItems"
            :value="totalItems > 0"
            :key="totalItems"
            transition="bounce"
            color="primary"
            overlap
          >
            <v-btn
              arial-label="Vai al tuo carrello"
              icon
              medium
              @click.stop="openCart()"
            >
              <!-- <v-icon medium>mdi-cart-outline</v-icon> -->
              <v-icon medium>$cart</v-icon>
            </v-btn>
          </v-badge>

          <div
            class="cart-total d-none d-md-flex"
            role="button"
            @click.stop="openCart()"
          >
            <strong>Carrello</strong>
            <span>{{ $n(cart.totalPrice, "currency") }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-progress-linear
      aria-label="Barra di avanzamento"
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="accent"
    ></v-progress-linear>
  </v-app-bar>
</template>
<script>
//TODO rivedere completamente la gestione dei breakpoint in modo che non si scassino su telefono....
import login from "~/mixins/login";

import SearchInput from "@/components/navigation/SearchInput";
import CartInfoList from "@/components/cart/CartInfoList";
import { forEachCartItem } from "~/service/ebsn";
import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login],
  components: { SearchInput, CartInfoList },
  data() {
    return {
      isMobile: false
      // key: 1
    };
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),

    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "Home",
            path: "/"
          });
        }, 200);
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    // onResize() {
    //   this.key = this.key + 1;
    // },
    historyBack() {
      window.history.back();
    },
    navTo(url) {
      window.open(url);
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
      // loading: state => state.loading
    }),
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      let total = 0;
      forEachCartItem(this.cart, function(item) {
        total += item.quantity > 0 ? item.quantity : 1;
      });
      return total;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  }
};
</script>
